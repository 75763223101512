<div class="overlay"></div>

<nav class="nav">
    <div class="sidenavi" gaCategory="comp_side_nav">

        <div class="menuRow">
            <a href="#" onclick="closeSideMenu();">
                <i class="arrowIcon highlighted mdi mdi-close-circle-outline right"></i>
            </a>
            <br clear="all">
        </div>

        <!-- Oberes Funktionsmenü je nach Loginzustand -->
        <div *ngIf="isLoggedIn() == true">
            <a routerLink="/profil" onclick="closeSideMenu();">
                <img class="circle" src="{{user.profileimage}}" width="50%" *ngIf="user != null && user.profileimage != null">
                <img class="circle" src="assets/images/user-placeholder.jpg" width="100%" *ngIf="user != null && user.profileimage == null">
                <br>
                <div class="waves-effect menuRow">
                    <span class="center-align">{{user.email}}</span>
                </div>
            </a>

            <div class="spacer"></div>

            <div class="waves-effect menuRow">
                <a routerLink="/" onclick="closeSideMenu();">
                    <i class="mdi mdi-ship-wheel left"></i>
                    <span class="title highlighted left">Startseite</span>
                    <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                </a>
            </div>

            <div class="waves-effect menuRow" *ngIf="hasPermissions('calls');">
                <a routerLink="/calls" onclick="closeSideMenu();">
                    <i class="mdi mdi-format-list-bulleted left"></i>
                    <span class="title highlighted left">Anrufe</span>
                    <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                    <br clear="all">
                </a>
            </div>

            <div class="waves-effect menuRow" *ngIf="hasPermissions('config');">
                <a routerLink="/config" onclick="closeSideMenu();">
                    <i class="mdi mdi-cellphone-cog left"></i>
                    <span class="title highlighted left">Einstellungen</span>
                    <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                    <br clear="all">
                </a>
            </div>

            <div class="waves-effect menuRow">
                <a routerLink="/account" onclick="closeSideMenu();">
                    <i class="mdi mdi-account-box-outline left"></i>
                    <span class="title highlighted left">Mein Konto</span>
                    <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                    <br clear="all">
                </a>
            </div>
    
        </div>

        <div *ngIf="isLoggedIn() == false">
            <img class="circle" src="assets/images/user-placeholder.jpg">
            <br>
            <div class="waves-effect menuRow">
                <span class="title center-align">Nicht eingeloggt</span>
            </div>

            <div class="spacer"></div>

            <div class="waves-effect menuRow">
                <a routerLink="/" onclick="closeSideMenu();">
                    <i class="mdi mdi-ship-wheel left"></i>
                    <span class="title highlighted left">Startseite</span>
                    <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                </a>
            </div>
        </div>

        <div class="waves-effect menuRow">
            <div class="divider"></div>
        </div>

        <!-- Standard Seiten wie Impressum usw -->
        <div class="waves-effect menuRow">
            <a href="mailto:support@hospitalis.io">
                <i class="mdi mdi-email-outline left"></i>
                <span class="title left">Kontakt</span>
                <!--span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span-->
                <br clear="all">
            </a>
        </div>

        <!--div class="waves-effect menuRow">
            <a routerLink="/impressum" onclick="closeSideMenu();">
                <i class="mdi mdi-format-section left"></i>
                <span class="title left">Impressum</span>
                <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                <br clear="all">
            </a>
        </div>

        <div class="waves-effect menuRow">
            <a routerLink="/agb" onclick="closeSideMenu();">
                <i class="mdi mdi-book-open-variant left"></i>
                <span class="title left">AGB</span>
                <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                <br clear="all">
            </a>
        </div-->

        <div class="waves-effect menuRow">
            <div class="divider"></div>
        </div>

        <!-- Unteres Funktionsmenü je nach Loginzustand -->
        <div *ngIf="isLoggedIn() == true">

            <!--div class="waves-effect menuRow">
                <a routerLink="/profil" onclick="closeSideMenu();">
                    <i class="mdi mdi-account-outline left"></i>
                    <span class="title highlighted left">Zum Profil</span>
                    <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                    <br clear="all">
                </a>
            </div-->

            <div class="waves-effect menuRow">
                <a routerLink="/logout" onclick="closeSideMenu();">
                    <i class="mdi mdi-exit-to-app left"></i>
                    <span class="title highlighted left">Ausloggen</span>
                    <!--span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span-->
                    <br clear="all">
                </a>
            </div>
        </div>        

        <div *ngIf="isLoggedIn() == false">
            <div class="waves-effect menuRow">
                <a routerLink="/registrieren" onclick="closeSideMenu();">
                    <i class="mdi mdi-format-list-checks left"></i>
                    <span class="title highlighted left">Registrieren</span>
                    <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                    <br clear="all">
                </a>
            </div>

            <div class="waves-effect menuRow">
                <a routerLink="/login" onclick="closeSideMenu();">
                    <i class="mdi mdi-key left"></i>
                    <span class="title highlighted left">Einloggen</span>
                    <span class="arrowIcon right"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></span>
                    <br clear="all">
                </a>
            </div>
        </div>

    </div>    
</nav>

<div class="burger">
    <span></span>
</div>
