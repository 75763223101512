<div class="card-panel" *ngIf="calls == null">
    <br><br>
    <div id="preloader"></div>
    <br>
    <div class="center-align">Bitte warten...</div>
</div>

<div *ngIf="calls != null">
    
    <ul class="calls" *ngIf="calls.length == 0">
        <li class="document-item card">
            <mat-card class="call-item">
                <mat-card-header>
                    <div mat-card-avatar><span class="mdi mdi-check"></span></div>
                    <mat-card-title>Sie haben keine Anrufe erhalten.</mat-card-title>
                    <mat-card-subtitle>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
                <mat-card-footer>
                </mat-card-footer>
            </mat-card>
        </li>
    </ul>

    <mat-accordion class="calls">
        <mat-expansion-panel class="call-item" *ngFor="let call of calls" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span *ngIf="!call.intent">
                        <span class="badge" [ngStyle]="{'background-color': stringToColor('Unknown'), 'color': textColorBasedOnBackground('Unknown')}" data-badge-caption="">{{intentLabel('Unknown')}}</span>
                    </span>
                    <span *ngIf="call.intent">
                        <span class="badge" [ngStyle]="{'background-color': stringToColor(call.intent), 'color': textColorBasedOnBackground(call.intent)}" data-badge-caption="">{{intentLabel(call.intent)}}</span>
                    </span>
                </mat-panel-title>
                <mat-panel-description>
                    <div class="datetime hide-on-small-only">
                        {{getTimestamp(call.timestamp) | date:'mediumDate':'':'de'}}, {{getTimestamp(call.timestamp) | date:'mediumTime':'':'de'}}</div>
                    <span *ngIf="!call.firstname && !call.lastname">Keine Angabe</span>
                    <span *ngIf="call.firstname || call.lastname">{{call.firstname}} {{call.lastname}}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-chip-set aria-label="Info">
                <mat-chip *ngIf="call.birthdate != null">
                    <span class="mdi mdi-cake-variant-outline"></span>
                    {{getTimestamp(call.birthdate) | date:'mediumDate':'':'de'}}
                </mat-chip>
                <mat-chip>
                    <span class="mdi mdi-phone"></span>
                    {{call.phonenumber}}
                </mat-chip>
            </mat-chip-set>

            <div class="call-content">
                <br>
                <div class="show-on-small-only"><span class="mdi mdi-clock-time-eight-outline"></span> {{getTimestamp(call.timestamp) | date:'mediumDate':'':'de'}}, {{getTimestamp(call.timestamp) | date:'mediumTime':'':'de'}}<br></div>
                <div *ngIf="call.summary" alt="Zusammenfassung"><span class="mdi mdi-robot-outline"></span> {{call.summary}}<br></div>
                <div *ngIf="call.transcript" alt="Transkript" class="transcript teal-text text-lighten-1"><span class="mdi mdi-account-voice"></span> {{call.transcript}}</div>

                <div *ngIf="call.attributes">
                    <br>
                    <mat-chip-set aria-label="Info">
                        <span *ngFor="let attribute of call.attributes | keyvalue">
                            <mat-chip *ngIf="attribute.value">
                                <span class="mdi mdi-lightbulb-on-10"></span>
                                {{attribute.key}}: {{attribute.value}}
                            </mat-chip>
                        </span>
                    </mat-chip-set>
                </div>
            </div>

            <mat-action-row>
                &nbsp;
                <a *ngIf="isDone(call)" class="waves-effect waves-light btn-large primary-bg" (click)="open(call)"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>Öffnen</a>
                <a *ngIf="isOpen(call)" class="waves-effect waves-light btn-large primary-bg" (click)="archive(call)"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>Abschließen</a>
            </mat-action-row>

        </mat-expansion-panel>
    </mat-accordion>
      
    <!--br>

    <div class="calls" *ngFor="let call of calls">
        <mat-card class="call-item">
            <mat-card-header>
                <span class="badge" *ngIf="call.intent" [ngStyle]="{'background-color': stringToColor(call.intent), 'color': textColorBasedOnBackground(call.intent)}" data-badge-caption="">{{intentLabel(call)}}</span>
                <mat-card-title>
                    <span *ngIf="!call.firstname && !call.lastname">Keine Angabe</span>
                    <span *ngIf="call.firstname || call.lastname">{{call.firstname}} {{call.lastname}}</span>
                </mat-card-title>
                <mat-card-subtitle>
                    {{dateLabel(call.timestamp)}} - {{getTimestamp(call.timestamp) | date:'mediumTime':'':'de'}}<br>
                    <mat-chip-set aria-label="Info">
                        <mat-chip *ngIf="call.birthdate != null">
                            <span class="mdi mdi-cake-variant-outline"></span>
                            {{getTimestamp(call.birthdate) | date:'mediumDate':'':'de'}}
                        </mat-chip>
                        <mat-chip>
                            <span class="mdi mdi-phone"></span>
                            {{call.phonenumber}}
                        </mat-chip>
                    </mat-chip-set>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div *ngIf="call.summary" alt="Zusammenfassung"><span class="mdi mdi-robot-outline"></span> {{call.summary}}<br></div>
                <div *ngIf="call.transcript" alt="Transkript" class="transcript teal-text text-lighten-1"><span class="mdi mdi-account-voice"></span> {{call.transcript}}</div>

                <div *ngIf="call.attributes">
                    <br>
                    <mat-chip-set aria-label="Info">
                        <span *ngFor="let attribute of call.attributes | keyvalue">
                            <mat-chip *ngIf="attribute.value">
                                <span class="mdi mdi-lightbulb-on-10"></span>
                                {{attribute.key}}: {{attribute.value}}
                            </mat-chip>
                        </span>
                    </mat-chip-set>
                </div>
                <br>
            </mat-card-content-->
            <!--mat-card-actions>
                <button mat-button>Anrufen</button>
            </mat-card-actions-->
            <!--mat-card-footer>
            </mat-card-footer>
        </mat-card>
    </div-->

</div>

