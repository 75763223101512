
<div class="card-panel" *ngIf="dialogConfig == null">
    <br><br>
    <div id="preloader"></div>
    <br>
    <div class="center-align">Bitte warten...</div>
</div>

<div *ngIf="dialogConfig != null">

    <div class="row card-panel toolbar">
        <div class="col s12 m8">
            Auf der folgenden Seite können Sie die die Dialogtext, Anliegen und die gewünschten Attribute konfigurieren.
        </div>
        <div class="col s12 m4 right-align">
            <span *ngIf="action == 'view'">
                <a class="waves-effect waves-dark btn-large white teal-text" (click)="edit()"><i class="mdi mdi-square-edit-outline left"></i>Bearbeiten</a>
            </span>

            <span *ngIf="action == 'edit'">
                <a class="waves-effect waves-dark btn-large white teal-text" (click)="cancel();"><i class="mdi mdi-close-box-outline left"></i>Abbrechen</a>
                &nbsp;
                <a class="waves-effect waves-light btn-large primary-bg" (click)="save()"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>Speichern</a>
            </span>
        </div>
    </div>

    <!--div class="row card-panel toolbar">
        <div class="col s12">
            <button mat-button (click)="edit()" class="toolbar-btn" *ngIf="action == 'view'"><i class="mdi mdi-playlist-edit hide-on-small-only"></i> Bearbeiten</button>
            <button mat-button (click)="save()" class="toolbar-btn" *ngIf="action == 'edit'"><i class="mdi mdi-content-save-outline hide-on-small-only"></i> Speichern</button>
        </div>
    </div-->

    <form [formGroup]="dialogFormGroup" class="dialog-form">

        <h2><span class="mdi mdi-cog-outline margin-right-10"></span> Konfiguration</h2>

        <mat-card class="margin-bottom-10">
            <mat-card-header>
                <mat-card-title>
                    Stimme
                </mat-card-title>
                <mat-card-subtitle>
                    Das ist die Stimme, die für die Dialoge verwendet wird.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div *ngIf="action == 'view'">
                    {{voiceString()}}&nbsp;                    
                </div>
                <!--
                <div *ngIf="action == 'edit'">
                    {{voiceString()}}&nbsp;                    
                </div>
                -->
                <div *ngIf="action == 'edit'" class="input-field">
                    <mat-form-field>
                        <mat-label>Stimme</mat-label>
                        <mat-select formControlName="voice">
                            <mat-option *ngFor="let voiceItem of voiceData" [value]="voiceItem.key">
                                {{voiceItem.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!--
                    <select class="browser-default custom-select" formControlName="voice">
                        <option *ngFor="let voiceItem of voiceData" [value]="voiceItem.key">{{voiceItem.value}}</option>
                    </select>
                    <mat-form-field class="input-full-width">
                        <mat-label>Stimme</mat-label>
                    </mat-form-field>
                    -->
                </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>
            </mat-card-footer>
        </mat-card>

        <mat-card class="margin-bottom-10">
            <mat-card-header>
                <mat-card-title>
                    Mögliche Anliegen
                </mat-card-title>
                <mat-card-subtitle>
                    Das ist eine Liste der möglichen Anliegen, die Sie zulassen möchten.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div *ngIf="action == 'view'">
                    <mat-chip-set aria-label="Info" *ngIf="dialogConfig.intents != null && dialogConfig.intents.length > 0">
                        <mat-chip *ngFor="let intent of dialogConfig.intents">
                            <span class="mdi mdi-account-voice"></span>
                            {{intent.name}}
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div *ngIf="action == 'edit'" class="input-field">
                    <mat-form-field class="input-full-width">
                        <mat-label>Anliegen</mat-label>
                        <mat-chip-grid #chipGridIntent aria-label="Anliegen eingeben">
                        <mat-chip-row *ngFor="let intent of dialogConfig.intents"
                                        (removed)="removeIntent(intent)"
                                        [editable]="true"
                                        (edited)="editIntent(intent, $event)"
                                        [aria-description]="'press enter to edit ' + intent.name">
                            {{intent.name}}
                            <button matChipRemove [attr.aria-label]="'remove ' + intent.name">
                                <span class="mdi mdi-close-circle-outline"></span>
                            </button>
                        </mat-chip-row>
                        <input matInput placeholder="Neuer Eintrag..."
                                [matChipInputFor]="chipGridIntent"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="addIntent($event)"/>
                        </mat-chip-grid>
                    </mat-form-field>

                </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>
            </mat-card-footer>
        </mat-card>

        <mat-card class="margin-bottom-10">
            <mat-card-header>
                <mat-card-title>
                    Aufzunehmende Daten
                </mat-card-title>
                <mat-card-subtitle>
                    Das ist eine Liste der Daten, die aus dem Transcript extrahiert werden sollen.<br>
                    Standardmässig werden Vorname, Nachname, Geburtsdatum und Anliegen extrahiert. Diese müssen nicht explizit aufgelistet werden.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div *ngIf="action == 'view'">
                    <mat-chip-set aria-label="Info" *ngIf="dialogConfig.attributes != null && dialogConfig.attributes.length > 0">
                        <mat-chip *ngFor="let attribute of dialogConfig.attributes">
                            <span class="mdi mdi-account-voice"></span>
                            {{attribute.name}}
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div *ngIf="action == 'edit'" class="input-field">
                    <mat-form-field class="input-full-width">
                        <mat-label>Attribute</mat-label>
                        <mat-chip-grid #chipGridAttributes aria-label="Attribute eingeben">
                        <mat-chip-row *ngFor="let attribute of dialogConfig.attributes"
                                        (removed)="removeAttribute(attribute)"
                                        [editable]="true"
                                        (edited)="editAttribute(attribute, $event)"
                                        [aria-description]="'press enter to edit ' + attribute.name">
                            {{attribute.name}}
                            <button matChipRemove [attr.aria-label]="'remove ' + attribute.name">
                                <span class="mdi mdi-close-circle-outline"></span>
                            </button>
                        </mat-chip-row>
                        <input matInput placeholder="Neuer Eintrag..."
                                [matChipInputFor]="chipGridAttributes"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="addAttribute($event)"/>
                        </mat-chip-grid>
                    </mat-form-field>

                </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>
            </mat-card-footer>
        </mat-card>

        <h2><span class="mdi mdi-chat-outline margin-right-10"></span> Konversationen</h2>

        <mat-card class="margin-bottom-10">
            <mat-card-header>
                <mat-card-title>
                    Begrüßungstext
                </mat-card-title>
                <mat-card-subtitle>
                    Dieser Text wird unmittelbar nach dem Start der Konversation ausgegeben.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div *ngIf="action == 'view'">
                    <i>"{{dialogConfig.greeting}}"</i>
                </div>
                <div *ngIf="action == 'edit'" class="input-field">
                    <mat-form-field class="input-full-width">
                        <mat-label>Ansagentext</mat-label>
                        <textarea matInput formControlName="greeting" required></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>
            </mat-card-footer>
        </mat-card>

        <mat-card class="margin-bottom-10">
            <mat-card-header>
                <mat-card-title>
                    Ansage 1 - Anliegen
                </mat-card-title>
                <mat-card-subtitle>
                    Hier geht es um das Anliegen der Anrufer.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div *ngIf="action == 'view'">
                    <i>"{{dialogConfig.message1}}"</i>
                </div>
                <div *ngIf="action == 'edit'" class="input-field">
                    <mat-form-field class="input-full-width">
                        <mat-label>Ansagentext</mat-label>
                        <textarea matInput formControlName="message1" required></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>
            </mat-card-footer>
        </mat-card>

        <mat-card class="margin-bottom-10">
            <mat-card-header>
                <mat-card-title>
                    Ansage 2 - Persönliches
                </mat-card-title>
                <mat-card-subtitle>
                    Dieser Text wird unmittelbar nach dem Start der Konversation ausgegeben.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div *ngIf="action == 'view'">
                    <i>"{{dialogConfig.message2}}"</i>
                </div>
                <div *ngIf="action == 'edit'" class="input-field">
                    <mat-form-field class="input-full-width">
                        <mat-label>Ansagentext</mat-label>
                        <textarea matInput formControlName="message2" required></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>
            </mat-card-footer>
        </mat-card>

        <mat-card class="margin-bottom-10">
            <mat-card-header>
                <mat-card-title>
                    Ansage 3 - Details zum Anliegen
                </mat-card-title>
                <mat-card-subtitle>
                    Dieser Text wird unmittelbar nach dem Start der Konversation ausgegeben.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div *ngIf="action == 'view'">
                    <i>"{{dialogConfig.message3}}"</i>
                </div>
                <div *ngIf="action == 'edit'" class="input-field">
                    <mat-form-field class="input-full-width">
                        <mat-label>Ansagentext</mat-label>
                        <textarea matInput formControlName="message3" required></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>
            </mat-card-footer>
        </mat-card>

        <mat-card class="margin-bottom-10">
            <mat-card-header>
                <mat-card-title>
                    Verabschiedung
                </mat-card-title>
                <mat-card-subtitle>
                    Dieser Text wird unmittelbar nach dem Start der Konversation ausgegeben.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <br>
                <div *ngIf="action == 'view'">
                    <i>"{{dialogConfig.goodbye}}"</i>
                </div>
                <div *ngIf="action == 'edit'" class="input-field">
                    <mat-form-field class="input-full-width">
                        <mat-label>Begrüßungstext</mat-label>
                        <textarea matInput formControlName="goodbye" required></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
            <mat-card-footer>
            </mat-card-footer>
        </mat-card>

    </form>

</div>

