<div class="container">
    <div class="section">

        <h1 class="bot-20 sec-tit">Anrufe</h1>

        <div class="row card-panel toolbar">
            <div class="col s12 m6">
                Anliegen:
                <select class="browser-default custom-select" [formControl]="callIntent" (change)="callIntentChanged($event)">
                    <option *ngFor="let callIntentItem of callIntentData" [ngValue]="callIntentItem.value">{{callIntentItem.key}}</option>
                </select>
            </div>
            <div class="col s12 m6">
                Status:
                <select class="browser-default custom-select" [formControl]="callStatus" (change)="callStatusChanged($event)">
                    <option *ngFor="let callStatusItem of callStatusData" [ngValue]="callStatusItem.value">{{callStatusItem.key}}</option>
                </select>
            </div>
        </div>

        <div class="row" #callsContent>
            <app-calllist [calls]="calls" [total]="totalCalls" (callChangeEvent)="callChanged($event)"></app-calllist>
            
            <div class="row card-panel">
                <div class="col s12">
                    <mat-paginator [length]="totalCalls"
                        [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions"
                        (page)="pageChanged($event)">
                    </mat-paginator>
                </div>
            </div>
        </div>

    </div>

</div>



